@tailwind base;
@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  h4 {
    @apply text-base;
  }
  h5 {
    @apply text-sm;
  }
  h6 {
    @apply text-xs;
  }
}
*:disabled {
  @apply opacity-60 cursor-not-allowed;
}
@tailwind components;
@tailwind utilities;


.tooltip {
  width: 25rem !important;
  border-radius: .5rem !important;
  padding: 1rem !important;
}